import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import NoRowsOverlay from '@/components/atoms/Icon/NoRowsOverlay'
import { AntiWarmingOriginateCreate, AntiWarmingReport, AntiWarmingReportList } from '@/openapi'
import theme from '@/theme'
import { convertToCsv } from '@/utils/csvHelper'
import useStore from '@/zustand/sotre'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import NoteFooterTable from '../../../Analysis/Tables/NoteFooterTable'
import AntiWarmingTableRow from './TableRow/AntiWarmingTableRow'
import { createAntiWarmingOriginate } from '@/services/store'
import { checkMessageText } from '@/utils/messageHelper'

const useStyle = makeStyles({
    container: {
        padding: 10,
        border: `1px solid ${theme.colors.border}`,
        marginTop: 125,
        transition: 'opacity 0.5s ease-in-out',
    },
    tableWrapper: {
        position: 'relative',
    },
    tableHead: {
        color: theme.colors.white,
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        backgroundColor: '#6E6E6E',
        borderRight: `4px solid ${theme.colors.white}`,
        borderBottom: `4px solid ${theme.colors.white}`,
    },
    firstHeader: {
        fontWeight: 600,
    },
    btnSaveData: {
        paddingTop: 80,
        paddingBottom: 10,
        position: 'fixed',
        top: 85,
        right: 22,
        zIndex: 1,
        backgroundColor: theme.colors.white,
        textAlign: 'end',
    },
    bodyFirstCell: {
        borderLeft: `1px dashed ${theme.colors.border}`,
    },
    bodyCell: {
        fontWeight: 300,
        fontSize: 14,
        borderRight: `1px dashed ${theme.colors.border}`,
    },
    tableFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: 400,
    },
    tag: {
        marginTop: 15,
        textAlign: 'left',
        color: theme.colors.black2,
    },
    tableBody: {
        maxHeight: 500,
        overflowX: 'auto',
    },
    styleRowTotal: {
        '& td': {
            fontWeight: 600,
        },
    },
})

interface TAntiWarmingTable {
    dataTable: AntiWarmingReport[]
    setCsvData?: React.Dispatch<React.SetStateAction<Blob | undefined>>
    yearSelect: number
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
}

export interface TItemAntiWarming {
    [key: string]: string | number | undefined
}

export interface TItemTotalCol {
    [key: string]: number
}

export interface TItemDataTableAntiWarming extends TItemAntiWarming {
    site_id: number
    total: number
}

export interface TItemDataCSV {
    id: number
    total: number
    name: string
    quantity: number[]
}

const getDataAntiWarming = (data: AntiWarmingReport, totalCol: TItemTotalCol) => {
    if (Array.isArray(data?.data)) {
        const data_anti_warming: TItemAntiWarming = {}
        let total = 0
        data.data.forEach((item: AntiWarmingReportList) => {
            if (item?.anti_warming_id && item?.quantity && item?.quantity > 0) {
                const key = `anti_warming_${item.anti_warming_id}`
                data_anti_warming[key] = item.quantity
                total += item?.quantity

                // vertical total
                if (!totalCol?.[key]) totalCol[key] = item?.quantity
                else if (totalCol?.[key]) totalCol[key] += item.quantity
            }
        })

        return {
            data_anti_warming,
            total,
        }
    }
    return {
        data_anti_warming: {},
        total: 0,
    }
}

function AntiWarmingTable(props: TAntiWarmingTable) {
    const classes = useStyle()
    const { expandedMenu, setMessage } = useStore()
    const [data, setData] = useState<TItemDataTableAntiWarming[] | []>([])
    const [dataOrigin, setDataOrigin] = useState<TItemDataTableAntiWarming[] | []>([])

    const [disableBtnSave, setDisableBtnSave] = useState(true)

    const headers = [
        { id: 0, name: '拠点名', width: 144 },
        { id: 0.5, name: '合計', width: 120 },
        { id: 1, name: '①エネルギー起源CO2', width: 120 },
        { id: 2, name: '②非エネルギー起源CO2', width: 120 },
        { id: 3, name: '③廃棄物の原燃料使用に伴う非エネルギー起源CO2', width: 128 },
        { id: 4, name: '④メタン', width: 120 },
        { id: 5, name: '⑤N2O', width: 120 },
        { id: 6, name: '⑥HFC', width: 120 },
        { id: 7, name: '⑦PFC', width: 120 },
        { id: 8, name: '⑧SF6', width: 120 },
        { id: 9, name: '⑨NF3', width: 120 },
        { id: 10, name: '⑩エネルギー起源CO2(発電所等配分前)', width: 150 },
    ]

    const getCountTotalCol = (totalCol: TItemTotalCol) => {
        let count = 0
        Object.keys(totalCol).forEach((item) => (count += totalCol[item]))
        return count
    }

    const genData = useMemo(() => {
        let data = JSON.parse(JSON.stringify(props.dataTable))
        // vertical total
        const totalCol: TItemTotalCol = {}
        if (Array.isArray(data)) {
            data = data.map((item) => {
                const { data_anti_warming, total } = getDataAntiWarming(item, totalCol)
                return {
                    site_id: item.site_id,
                    name: item.site_name,
                    ...data_anti_warming,
                    total: total,
                }
            })
        }
        data.push({
            site_id: 0,
            name: '合計',
            ...totalCol,
            total: getCountTotalCol(totalCol),
        })
        return data
    }, [props.dataTable])

    const getDataCSV = useMemo(() => {
        const headersCSV = [...headers].slice(2)
        let dataCSV: TItemDataCSV[] = []

        const getQuantity = (data: TItemDataTableAntiWarming) => {
            return Array.from({ length: 10 }, (_, index: number) => {
                const quantity = data?.[`anti_warming_${index + 1}`] || 0
                return quantity
            })
        }

        dataCSV = JSON.parse(JSON.stringify(data)).map((item: TItemDataTableAntiWarming) => {
            return {
                id: item.site_id,
                total: item.total,
                name: item.name,
                quantity: getQuantity(item),
            }
        })
        return { dataCSV, headersCSV }
    }, [data])

    const checkChange = (dataOrigin: AntiWarmingReport[], dataRow: TItemDataTableAntiWarming) => {
        const dataRowOrigin = dataOrigin.find((item) => item.site_id === dataRow.site_id)
        const dataInputOrigin = dataRowOrigin?.data?.find((item) => item.anti_warming_id === 10)
        if (dataInputOrigin?.quantity !== dataRow?.['anti_warming_10']) {
            return true
        } else return false
    }

    const onSaveDataInput = async () => {
        try {
            const params: AntiWarmingOriginateCreate = {
                year: props.yearSelect,
                data: [],
            }
            if (Array.isArray(data) && data.length) {
                params.data = [...data]
                    .filter((item) => item.site_id !== 0 && checkChange(props.dataTable, item))
                    .map((item) => ({
                        value: Number(item?.['anti_warming_10']) || 0,
                        site_id: item?.site_id,
                    }))
            }
            if (params.data.length > 0) await createAntiWarmingOriginate(params)
            props.setIsFetching((preState) => !preState)
        } catch (error: any) {
            console.error({ error })
            const message = checkMessageText(error?.status, error?.data?.errors?.[0]?.message)
            setMessage({ message, type: 'error' })
        }
    }

    useEffect(() => {
        setDataOrigin(JSON.parse(JSON.stringify(genData)))
        setData(genData)
    }, [props.dataTable])

    useEffect(() => {
        if (JSON.stringify(dataOrigin) !== JSON.stringify(data)) setDisableBtnSave(false)
        else setDisableBtnSave(true)

        if (props.setCsvData) {
            const { dataCSV, headersCSV } = getDataCSV
            const typeData = 'anti-warming'
            const writeData = convertToCsv(dataCSV, headersCSV, true, typeData)
            props.setCsvData(writeData)
        }
    }, [data])

    return (
        <>
            <div>
                <div className={classes.btnSaveData} style={{ width: `calc(100% - ${expandedMenu ? 300 : 120}px)` }}>
                    <div>
                        <PrimaryButton
                            style={{ fontWeight: 400 }}
                            width={215}
                            height={38}
                            fontSize={15}
                            disabled={disableBtnSave}
                            onClick={onSaveDataInput}
                        >
                            入力したデータを保存する
                        </PrimaryButton>
                    </div>
                </div>
                <div className={classes.container}>
                    <TableContainer className={classes.tableWrapper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headers.map((h, i) => (
                                        <TableCell
                                            key={h.name}
                                            style={{
                                                minWidth: h.width,
                                                maxHeight: 64,
                                                verticalAlign: h.id === 0 || h.id === 0.5 ? 'center' : 'top',
                                                padding: h.id === 0 || h.id === 0.5 ? '12px 8px' : '12px 8px 4px 8px',
                                                fontSize: 14,
                                            }}
                                            width={h.width}
                                            height={64}
                                            className={`${classes.tableHead} ${h.id === 0 ? classes.firstHeader : ''}`}
                                        >
                                            <div>{h.name}</div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.length > 1 ? (
                                    data.map((b, i) => (
                                        <AntiWarmingTableRow
                                            key={b.site_id}
                                            dataRow={b}
                                            index={i}
                                            setData={setData}
                                            data={data}
                                            classNameRow={b.site_id === 0 ? classes.styleRowTotal : ''}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={12}
                                            align="center"
                                            className={`${classes.bodyFirstCell} ${classes.bodyCell}`}
                                        >
                                            <NoRowsOverlay />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.tableFooter}>
                        <div className={classes.tag}>単位：t-CO&#8322;e</div>
                        <NoteFooterTable fontSize={12} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(AntiWarmingTable)
