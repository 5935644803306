export const checkMessageText = (statusCode: number, defaultMess?: string) => {
    switch (statusCode) {
        case 500: {
            return '予期せぬエラー'
        }
        case 409: {
            return 'このEメールが既に存在しています。'
        }
        case 429: {
            return 'リクエストが多すぎる'
        }
        default: {
            return defaultMess ? defaultMess : ''
        }
    }
}
